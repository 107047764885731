<div class="nav-container d-f ai-c w-100 mt-16">
  <div class="fg-1"></div>
  <div class="d-f ai-c c-golem fw-bold fs-14 c-pointer" [matMenuTriggerFor]="menu">
    {{ username ? username : '' }} <mat-icon class="c-geodude" livesafeMat>expand_more</mat-icon>
  </div>
  <mat-menu class="mt-8" livesafeMat #menu="matMenu">
    <button mat-menu-item (click)="onLogout()">
      <mat-icon livesafeMat>exit_to_app</mat-icon> Logout
    </button>
  </mat-menu>
</div>
