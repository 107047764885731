import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

import { ThumbnailSize } from '../models/media.model';
import { MediaId } from '../models/type-tokens';

export const GET_MEDIA_API = `${environment.DASHBOARD_APP_API}/media`;

@Injectable({
  providedIn: 'root'
})
export class MediaIoService {
  constructor(private http: HttpClient) {}

  fetchMedia(mediaId: MediaId): Observable<Blob> {
    return this.http.get(`${GET_MEDIA_API}/${mediaId}`, {
      responseType: 'blob'
    });
  }

  fetchThumbnail(mediaId: MediaId, thumbnailSize: ThumbnailSize): Observable<Blob> {
    return this.http.get(`${GET_MEDIA_API}/${mediaId}/thumbnail?size=${thumbnailSize}`, {
      responseType: 'blob'
    });
  }

  fetchExternalMedia(url: string) {
    return this.http.get(url, {
      withCredentials: false,
      responseType: 'blob'
    });
  }
}
