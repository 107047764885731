import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';
import { Enterprise } from '@dashboard/core/models';
import { SessionService, WindowService } from '@dashboard/core/services';
import { environment, RuntimeEnv } from 'src/environments/environment';
import { Observable, of } from 'rxjs';
import { catchError, map, take, timeout } from 'rxjs/operators';

import { EnterpriseService } from '../services/enterprise.service';

@Injectable({
  providedIn: 'root'
})
export class EnterpriseGuard implements CanActivate, CanActivateChild {
  constructor(
    private enterpriseService: EnterpriseService,
    private windowService: WindowService,
    private session: SessionService
  ) {}

  canActivate(): Observable<boolean> {
    return this._canActivate();
  }

  canActivateChild(): Observable<boolean> {
    return this._canActivate();
  }

  private _canActivate(): Observable<boolean> {
    if (environment.RUNTIME_ENV === RuntimeEnv.LocalDev) {
      // Set dummy enterprise for local development
      this.session.setCurrentEnterprise({
        id: '2',
        name: 'Test Enterprise',
        subdomains: ['dashboard']
      } as Enterprise);
      return of(true);
    }

    try {
      this.session.getCurrentEnterprise();
      return of(true);
    } catch (_) {
      return this.enterpriseService.getEnterprise(this.windowService.subdomain).pipe(
        take(1),
        map(enterprise => {
          this.session.setCurrentEnterprise(enterprise);
          return true;
        }),
        timeout(60000),
        catchError(() => {
          this.windowService.navigateToRootDomain();
          return of(false);
        })
      );
    }
  }
}
