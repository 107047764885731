import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
  UrlSegment
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this._canActivate(route, state);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this._canActivate(route, state);
  }

  private _canActivate(
    route: ActivatedRouteSnapshot,
    _: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    const redirectUrl: string = this.router
      .createUrlTree(route.url.map(segment => segment.path), {
        // remove authTokenId
        queryParams: { ...route.queryParams, authTokenId: undefined }
      })
      .toString()
      // remove leading "/"
      .substring(1);

    const loginUrlTree: UrlTree = this.router.createUrlTree(['']);
    if (redirectUrl) {
      loginUrlTree.queryParams = {
        redirect: btoa(redirectUrl)
      };
    }

    const authTokenId = route.queryParams && route.queryParams['authTokenId'];
    if (authTokenId) {
      /**
       * Directly accessing a _ property is a bad idea. However, there's no other current way of getting all the segments
       * that make up the URL without recursively going through each of the children routes and their children routes etc
       * There doesn't seem to be an API to directly access the UrlSegmentGroup which is what we access here from the
       * _ property 🤷‍♂️
       */
      // @ts-ignore
      const segments: UrlSegment[] = route._urlSegment.segments;

      return this.auth.loginWithTokenId(authTokenId).pipe(
        map(() =>
          // Navigate to intercepted url, remove authTokenId
          this.router.createUrlTree(segments.map(segment => segment.path), {
            queryParams: { ...route.queryParams, authTokenId: undefined }
          })
        ),
        catchError(() => of(loginUrlTree))
      );
    }

    return this.auth.isAuthenticated().pipe(
      take(1),
      map(res => {
        if (!res) {
          return loginUrlTree;
        }

        return res;
      }),
      catchError(() => of(loginUrlTree))
    );
  }
}
