import {
  Component,
  ElementRef,
  HostListener,
  Input,
  ViewChild,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { ContentType } from '@dashboard/core';

@Component({
  selector: 'dash-video-player',
  template: `
    <div class="video-wrapper" (click)="toggleVideo()">
      <div class="play-button" *ngIf="videoPlayer.paused">
        <i class="icon-play"></i>
      </div>
      <video preload #videoPlayer>
        <source [src]="src" />
        <source [src]="src" [type]="contentType" />
      </video>
    </div>
  `,
  styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnChanges {
  @Input() src: string;
  @Input() contentType: ContentType;

  @ViewChild('videoPlayer') videoPlayer: ElementRef<HTMLVideoElement>;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.src.currentValue && this.videoPlayer) {
      this.videoPlayer.nativeElement.load();
    }
  }
  @HostListener('window:keyup.space')
  toggleVideo(): void {
    const video: HTMLVideoElement = this.videoPlayer.nativeElement;
    if (video.paused) {
      video.controls = true;
      setTimeout(() => {
        video.play();
      }, 100);
    } else {
      video.pause();
      video.controls = false;
    }
  }

  @HostListener('window:keyup.arrowleft', ['$event'])
  @HostListener('window:keyup.arrowright', ['$event'])
  skip($event: KeyboardEvent): void {
    const video: HTMLVideoElement = this.videoPlayer.nativeElement;

    switch ($event.key) {
      case 'ArrowLeft':
        video.currentTime--;
        break;
      case 'ArrowRight':
        video.currentTime++;
        break;
      default:
        break;
    }
  }
}
