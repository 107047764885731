<button
  mat-icon-button
  livesafeMat
  border="solid"
  class="close-button c-dewgong"
  aria-label="cancel creating organization"
  (click)="onCancel()"
>
  <mat-icon fontSet="material-icons-outlined">clear</mat-icon>
</button>
<dash-create-organization
  [parentOrgId]="data?.parentOrgId"
  [firstOrg]="false"
  (orgCreated)="onOrgCreated($event)"
></dash-create-organization>
