import { Component } from '@angular/core';
import { AuthService, SessionService } from '@dashboard/core';

@Component({
  selector: 'dash-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss']
})
export class TopNavComponent {
  username: string;

  constructor(private session: SessionService, private $auth: AuthService) {
    try {
      this.username = this.session.getCurrentAccount().mediumName!;
    } catch { }
  }

  onLogout(): void {
    this.$auth.logout().subscribe();
  }
}
