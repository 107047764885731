import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { FeedIoService, FeedRequest, FeedResponse } from './feed-io.service';

@Injectable({
  providedIn: 'root'
})
export class FeedService {
  constructor(private $io: FeedIoService) { }

  getFeed(skip: number = 0, limit: number = 50, searchTerm?: string): Observable<FeedResponse> {
    const requestOptions: FeedRequest = { skip, limit };
    let request = this.$io.fetchFeed(requestOptions);

    if (searchTerm && searchTerm.length >= 3) {
      requestOptions.skip = 0;
      requestOptions.search = searchTerm;
      request = this.$io.fetchFeedSearchResults(requestOptions);
    }

    return request;
  }

  updateFeed(
    minimumUpdatedAt: number,
    searchTerm?: string,
    limit: number = 50
  ): Observable<FeedResponse> {
    const requestOptions: FeedRequest = {
      skip: 0,
      limit,
      minimumUpdatedAt
    };

    if (searchTerm) {
      requestOptions.search = searchTerm;
    }

    return this.$io.fetchFeed(requestOptions);
  }

  getHasUnread(): Observable<boolean> {
    return this.$io.fetchHasUnread();
  }
}
