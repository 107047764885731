import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Organization } from '@dashboard/core';

@Component({
  selector: 'dash-create-organization-modal',
  templateUrl: './create-organization-modal.component.html',
  styleUrls: ['./create-organization-modal.component.scss']
})
export class CreateOrganizationModalComponent {
  constructor(
    public dialog: MatDialogRef<CreateOrganizationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  onOrgCreated($event: Organization): void {
    this.dialog.close($event);
  }

  onCancel(): void {
    this.dialog.close();
  }
}
