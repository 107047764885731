import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Account } from '@dashboard/core/models';
import { environment } from '@dashboard/env';
import { Observable } from 'rxjs';

import { OrganizationTree } from '../models/organization.model';
import { AccountId, EmailAddress, EnterpriseId } from '../models/type-tokens';
import { SessionService } from './session.service';

export const LOGIN_API = `${environment.DASHBOARD_APP_API}/auth/login`;
export const TOKEN_LOGIN_API = `${environment.DASHBOARD_APP_API}/auth/tokenLogin`;
export const LOGOUT_API = `${environment.DASHBOARD_APP_API}/auth/logout`;
export const CLIENT_ONBOARDING_TOKEN_LOGIN_API = `${environment.DASHBOARD_APP_API}/auth/clientOnboardingTokenLogin`;
export const PROVIDER_LOGIN_API = `${environment.DASHBOARD_APP_API}/auth/cognitologin`;
export const IS_AUTHENTICATED_API = `${environment.DASHBOARD_APP_API}/auth/session`;
export const FORGOT_PASSWORD_API = `${environment.DASHBOARD_APP_API}/auth/forgotPassword`;
export const CHANGE_PASSWORD_API = `${environment.DASHBOARD_APP_API}/auth/changePassword`;

export interface SessionResponse {
  accountId: AccountId;
  expirationDateTime: string;
  id: string;
}

export interface LoginRequest {
  enterpriseId: EnterpriseId;
  emailAddress: EmailAddress;
  password: string;
  rememberMe: boolean;
}

export interface LoginResponse {
  account: Account;
  organizations: OrganizationTree[];
}

export interface ForgotPasswordRequest {
  enterpriseId: EnterpriseId;
  emailAddress: EmailAddress;
}

export interface ChangePasswordRequest {
  token: string;
  newPassword: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthIoService {
  constructor(private http: HttpClient, private $session: SessionService) { }

  postLogin(
    emailAddress: EmailAddress,
    password: string,
    rememberMe: boolean = false
  ): Observable<LoginResponse> {
    const enterpriseId: EnterpriseId = this.$session.getCurrentEnterprise().id;

    return this.http.post<LoginResponse>(LOGIN_API, <LoginRequest>{
      enterpriseId,
      emailAddress,
      password,
      rememberMe
    });
  }

  postTokenIdLogin(tokenId: string): Observable<LoginResponse> {
    const params: HttpParams = new HttpParams({ fromObject: { tokenId } });

    return this.http.post<LoginResponse>(TOKEN_LOGIN_API, null, { params });
  }

  postLoginWithClientOnboardingToken(onboardingToken: string): Observable<LoginResponse> {
    const params: HttpParams = new HttpParams({ fromObject: { onboardingToken } });

    return this.http.post<LoginResponse>(CLIENT_ONBOARDING_TOKEN_LOGIN_API, null, { params });
  }

  fetchIsAuthenticated(): Observable<SessionResponse> {
    return this.http.get<SessionResponse>(IS_AUTHENTICATED_API);
  }

  postLogout(): Observable<void> {
    return this.http.post<void>(LOGOUT_API, null);
  }

  postForgotPassword(emailAddress: EmailAddress): Observable<void> {
    const request: ForgotPasswordRequest = <ForgotPasswordRequest>{
      enterpriseId: this.$session.getCurrentEnterprise().id,
      emailAddress
    };

    return this.http.post<void>(FORGOT_PASSWORD_API, request);
  }

  postChangePassword(token: string, newPassword: string): Observable<void> {
    const request: ChangePasswordRequest = <ChangePasswordRequest>{ token, newPassword };

    return this.http.post<void>(CHANGE_PASSWORD_API, request);
  }
}
