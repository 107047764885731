import { OverlayRef } from '@angular/cdk/overlay';
import { Component, HostListener, Input } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { Attachment, ContentType, ContentTypeUtils } from '@dashboard/core';

import { MediaService } from '../../../core/services/media.service';

@Component({
  selector: 'dash-media-carousel',
  template: `
    <div class="menu-bar">
      <div class="button-container">
        <!-- TODO: Not MVP -->
        <!--<button><i class="icon-download"></i></button>-->
        <!--<button><i class="icon-print"></i></button>-->
        <button
          (click)="close()"
          aria-label="close media carousel"
          class="close-button"
          dashFocusOnInit
        >
          <i class="icon-x"></i>
        </button>
      </div>
    </div>
    <div class="stage">
      <div class="info">
        <div class="title"></div>
        <div class="thumbnail-container" *ngIf="hasNext() || hasPrevious()">
          <div class="thumbnail" *ngFor="let attachment of media; let i = index">
            <dash-image
              *ngIf="isImage(attachment.contentType)"
              [mediaId]="attachment.mediaId"
              type="thumbnail"
              [ngClass]="{ selected: i === selectedIndex }"
              (selected)="select(i)"
              role="button"
              tabindex="0"
              [attr.aria-label]="'view image ' + i + ' in media carousel'"
            ></dash-image>

            <div class="play-button" *ngIf="isVideo(attachment.contentType)">
              <i class="icon-play"></i>
            </div>
            <!-- TODO: using <video> tag as thumbnail is temporary solution while video processing is implemented in Thumbnail core service (NEX-1052) -->
            <video
              *ngIf="isVideo(attachment.contentType)"
              [ngClass]="{ selected: i === selectedIndex }"
              preload
              role="button"
              aria-label="Navigate to selected video in media carousel"
            >
              <source [src]="getVideoUrl(attachment)" />
              <source [src]="getVideoUrl(attachment)" [type]="attachment.contentType" />
            </video>
          </div>
        </div>
      </div>
      <div class="carousel">
        <button
          class="previous-button"
          [disabled]="!hasPrevious()"
          (click)="previous()"
          *ngIf="media.length > 1"
        >
          <img src="assets/icons/arrow-left.svg" />
        </button>
        <dash-image
          *ngIf="isImage(selected?.contentType)"
          [mediaId]="selected.mediaId"
        ></dash-image>
        <dash-video-player
          *ngIf="isVideo(selected?.contentType)"
          [src]="getVideoUrl(selected)"
          [contentType]="selected?.contentType"
        ></dash-video-player>

        <button
          class="next-button"
          [disabled]="!hasNext()"
          (click)="next()"
          *ngIf="media.length > 1"
        >
          <img src="assets/icons/arrow-right.svg" />
        </button>
      </div>
    </div>
  `,
  styleUrls: ['./media-carousel.component.scss']
})
export class MediaCarouselComponent {
  @Input() media: Attachment[] = [];
  @Input() selectedIndex: number = 0;

  get selected(): Attachment {
    return this.media[this.selectedIndex];
  }

  constructor(private overlayRef: OverlayRef, private $media: MediaService) { }

  @HostListener('window:keyup.esc')
  close(): void {
    this.overlayRef.dispose();
  }

  select(index: number): void {
    this.selectedIndex = index;
  }

  hasPrevious(): boolean {
    return this.selectedIndex !== 0;
  }

  @HostListener('window:keyup.arrowleft')
  previous(): void {
    if (this.hasPrevious()) {
      this.selectedIndex--;
    }
  }

  hasNext(): boolean {
    return this.selectedIndex !== this.media.length - 1;
  }

  @HostListener('window:keyup.arrowright')
  next(): void {
    if (this.hasNext()) {
      this.selectedIndex++;
    }
  }

  isImage(contentType: ContentType): boolean {
    return new ContentTypeUtils(contentType).isImage();
  }

  isVideo(contentType: ContentType): boolean {
    return new ContentTypeUtils(contentType).isVideo();
  }

  getVideoUrl(attachment: Attachment): SafeUrl {
    return this.$media.getVideoUrl(attachment.mediaId);
  }
}
