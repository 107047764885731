import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard, EnterpriseGuard, rootDomainRouteMatcher } from '@dashboard/core';

const routes: Routes = [
  {
    // TODO: remove <any> once https://github.com/angular/angular/issues/21624 fixed
    matcher: <any>rootDomainRouteMatcher,
    loadChildren: () => import('./onboarding/onboarding.module').then(m => m.OnboardingModule)
  },
  {
    path: 'testing',
    loadChildren: () =>
      import('./public/playground/playground.module').then(m => m.PlaygroundModule)
  },
  {
    path: 'youre-invited',
    loadChildren: () =>
      import('./public/youre-invited/youre-invited.module').then(m => m.YoureInvitedModule)
  },
  {
    path: 'jotform',
    loadChildren: () => import('./public/jotform/jotform.module').then(m => m.JotformModule)
  },
  {
    path: 'home',
    canActivate: [AuthGuard, EnterpriseGuard],
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'insights',
    canActivate: [AuthGuard, EnterpriseGuard],
    loadChildren: () => import('./insights/insights.module').then(m => m.InsightsModule)
  },
  {
    path: 'connect',
    canActivate: [AuthGuard, EnterpriseGuard],
    loadChildren: () => import('./connect/connect.module').then(m => m.ConnectModule)
  },
  {
    path: 'broadcasts',
    canActivate: [AuthGuard],
    loadChildren: () => import('./broadcast/broadcast.module').then(m => m.BroadcastModule)
  },
  {
    path: 'menu',
    canActivate: [AuthGuard],
    loadChildren: () => import('./menu/menu.module').then(m => m.MenuModule)
  },
  {
    path: 'communications',
    canActivateChild: [AuthGuard],
    loadChildren: () =>
      import('./communications/communications.module').then(m => m.CommunicationsModule)
  },
  {
    path: 'tips',
    canActivateChild: [AuthGuard, EnterpriseGuard],
    loadChildren: () =>
      import('./tips/tips.module').then(m => m.TipsModule)
  },
  {
    path: 'organizations',
    canActivateChild: [AuthGuard],
    loadChildren: () =>
      import('./organizations/organizations.module').then(m => m.OrganizationsModule)
  },
  {
    path: 'users',
    canActivateChild: [AuthGuard],
    loadChildren: () => import('./accounts/accounts.module').then(m => m.AccountsModule)
  },
  {
    path: 'create-organization',
    canActivateChild: [AuthGuard, EnterpriseGuard],
    loadChildren: () =>
      import('./create-organization/create-organization.module').then(
        m => m.CreateOrganizationModule
      )
  },
  {
    path: 'groups',
    canActivateChild: [AuthGuard],
    loadChildren: () => import('./groups/groups.module').then(m => m.GroupsModule)
  },
  {
    path: 'tip-type-config',
    canActivate: [AuthGuard, EnterpriseGuard],
    loadChildren: () => import('./tip-type-config/tip-type-config.module').then(m => m.TipTypeConfigModule)
  },
  {
    path: 'deeplink',
    loadChildren: () =>
      import('./public/deeplink/deeplink.module').then(
        m => m.DeeplinkModule
      )
  },
  {
    path: '',
    // TODO: re-add LoggedInGuard when there is logic to redirect users to a homepage based on permissions (prevent infinite redirects)
    // LoggedInGuard,
    canActivateChild: [EnterpriseGuard],
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },
  {
    path: '**',
    redirectTo: 'home'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
      /**
       * Important not to remove `preloadingStrategy: PreloadAllModules`.
       * This is required as we have rolling deployments of the Nginx containers
       * that this application runs in.
       *
       * All assets must be sent to the client as soon as the application loads
       * since the container may be replaced while the app is open.
       *
       * With preloading, we can still benefit from lazy loading. See:
       * https://vsavkin.com/angular-router-preloading-modules-ba3c75e424cb for a
       * more in-depth explanation.
       */
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
