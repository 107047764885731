import { OverlayRef } from '@angular/cdk/overlay';
import { Component, HostListener, Input } from '@angular/core';
import { FileModel } from 'src/app/core/models/file.model';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'dash-upload-carousel',
  templateUrl: './upload-carousel.component.html',
  styleUrls: ['./upload-carousel.component.scss']
})
export class UploadCarouselComponent {
  @Input() media: FileModel[] = [];
  @Input() selectedIndex: number = 0;

  get selected(): FileModel {
    return this.media[this.selectedIndex];
  }

  constructor(private overlayRef: OverlayRef, private $sanitizer: DomSanitizer) { }

  @HostListener('window:keyup.esc')
  close(): void {
    this.overlayRef.dispose();
  }

  select(index: number): void {
    this.selectedIndex = index;
  }

  hasPrevious(): boolean {
    return this.selectedIndex !== 0;
  }

  @HostListener('window:keyup.arrowleft')
  previous(): void {
    if (this.hasPrevious()) {
      this.selectedIndex--;
    }
  }

  hasNext(): boolean {
    return this.selectedIndex !== this.media.length - 1;
  }

  @HostListener('window:keyup.arrowright')
  next(): void {
    if (this.hasNext()) {
      this.selectedIndex++;
    }
  }
  getVideoUrl(attachment: FileModel): SafeUrl {
    return (
      attachment.data || this.$sanitizer.bypassSecurityTrustUrl(attachment.preUploadedDataUrl || '')
    );
  }
}
