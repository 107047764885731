<div class="media-files-wrapper" [class.hide]="hide">
  <ng-container *ngIf="!readonly">
    <button
      type="button"
      mat-mini-fab
      livesafeMat
      aria-label="Attach media"
      (click)="file.click()"
      color="accent"
    >
      <mat-icon>image</mat-icon>
    </button>
    <div class="media-message mat-body-strong" *ngIf="!fileList.length">Attach Media</div>
    <form>
      <input
        type="file"
        [name]="name"
        [multiple]="multiple"
        [accept]="accept"
        (change)="onChange(file.files, file.form)"
        #file
      />
    </form>
  </ng-container>
  <ng-container *ngIf="fileList.length">
    <div class="image-wrapper-wrapper">
      <ng-container *ngFor="let file of fileList; let i = index">
        <div class="image-wrapper" *ngIf="!file.error">
          <div class="media-files">
            <div
              class="image"
              *ngIf="file.format === 'image'"
              (click)="openMediaModal(i)"
              [style.background-image]="'url(' + file.preview + ')'"
            ></div>
            <video *ngIf="file.format === 'video' && file.data" preload (click)="openMediaModal(i)">
              <source [src]="file.preview" />
              <source [src]="file.preview" [type]="file.data.type" />
            </video>
            <img
              *ngIf="file.format === 'audio'"
              (click)="openMediaModal(i)"
              src="assets/icons/audio.svg"
            />
            <mat-icon
              livesafeMat
              color="warn"
              aria-label="Remove attached media"
              (click)="removeFile(i)"
              *ngIf="!readonly"
              class="cancel"
              >cancel</mat-icon
            >
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
