export enum OrganizationPageNames {
  HomeScreen = 'Home Screen Config'
}

export enum InsightsSegmentPageNames {
  InsightsLanding = 'Insights Landing'
}

export enum BroadcastSegmentPageNames {
  BroadcastHome = 'Broadcast Home',
  BroadcastDetails = 'Broadcast Details',
  ComposeBroadcastComposeMessage = 'Compose Broadcast - Compose Message',
  ComposeBroadcastPreviewMessage = 'Compose Broadcast - Preview Message',
  ComposeBroadcastSelectRecipients = 'Compose Broadcast - Select Recipients',
  ComposeBroadcastSummary = 'Compose Broadcast - Summary',
  ComposeBroadcastSendModal = 'Compose Broadcast - Send Modal',
  CheckInResponseWebView = 'Check-In Response Web View',
  ComposeBroadcastScheduleModal = 'Compose Broadcast - Schedule Modal',
}

export enum ConnectSegmentPageNames {
  ConnectActivity = 'Connect Activity',
  Conversation = 'Connect Conversation',
  ConversationCreated = 'Connect Conversation Created',
  CreateConversationAddMessage = 'Create Connect Conversation - Add Message',
  CreateConversationAddLocation = 'Create Connect Conversation - Add Location',
  CreateConversationAddParticipants = 'Create Connect Conversation - Add Participants',
  CreateConversationConfirmation = 'Create Connect Conversation - Confirmation'
}
