import 'angular-server-side-configuration/process';
/**
 * How to use angular-server-side-configuration:
 *
 * Use process.env.NAME_OF_YOUR_ENVIRONMENT_VARIABLE
 *
 * export const environment = {
 *   stringValue: process.env.STRING_VALUE,
 *   stringValueWithDefault: process.env.STRING_VALUE || 'defaultValue',
 *   numberValue: Number(process.env.NUMBER_VALUE),
 *   numberValueWithDefault: Number(process.env.NUMBER_VALUE || 10),
 *   booleanValue: Boolean(process.env.BOOLEAN_VALUE),
 *   booleanValueInverted: process.env.BOOLEAN_VALUE_INVERTED !== 'false',
 * };
 */

export const enum RuntimeEnv {
  Production = 'prod',
  Dev = 'dev',
  Stg = 'stg',
  LocalDev = 'local_dev',
  Testing = 'testing'
}

export const environment = {
  DASHBOARD_APP_API: process.env.DASHBOARD_APP_API || '/api/v1',
  DASHBOARD_APP_API_V2: process.env.DASHBOARD_APP_API_V2 || '/api/v2',
  DASHBOARD_HOST: process.env.DASHBOARD_HOST || 'localhost:4200',
  GMAPS_API_KEY: process.env.GMAPS_API_KEY || 'AIzaSyCWBFTaQwY2RGPLZ3-OtcAPuYTrux6olbw',
  RUNTIME_ENV: process.env.RUNTIME_ENV || 'local_dev',
  PROD_MODE: Boolean(process.env.PROD_MODE === 'true') || false,
  CONNECT_CHAT_POLL_INTERVAL: Number(process.env.CONNECT_CHAT_POLL_INTERVAL) || 3000,
  CONNECT_ACTIVITY_POLL_INTERVAL: Number(process.env.CONNECT_ACTIVITY_POLL_INTERVAL) || 3000,
  SEGMENT: process.env.SEGMENT || '9rIvt22HutfFCBSEjnPFTW2nuFbbhFDH',
  HOMESCREEN_ASSETS: process.env.HOMESCREEN_ASSETS || 'https://static.livesafemobile.net',
  BROADCAST_STATS_POLL_INTERVAL: Number(process.env.BROADCAST_STATS_POLL_INTERVAL) || 5000,
  BROADCAST_STATS_POLL_TIME: Number(process.env.BROADCAST_STATS_POLL_TIME) || 60000,
  NGXS_DEVELOPMENT_MODE: Boolean(process.env.PROD_MODE !== 'true'),
  SENTRY_DSN: process.env.SENTRY_DSN || 'https://77b1d746b7f541eaa5c935cc7c8871ca@o314392.ingest.sentry.io/5195181',
  TIP_FEED_POLL_INTERVAL: Number(process.env.TIP_FEED_POLL_INTERVAL) || 3000,
  TIP_CHAT_POLL_INTERVAL: Number(process.env.TIP_CHAT_POLL_INTERVAL) || 30000,
  PUBNUB_PUBLISH_KEY: process.env.PUBNUB_PUBLISH_KEY || 'pub-c-273872ed-3de5-4165-a05d-d0a0b2251687',
  PUBNUB_SUBSCRIBE_KEY: process.env.PUBNUB_SUBSCRIBE_KEY || 'sub-c-482ce054-e6a4-11e7-b8a6-46d99af2bb8c',
  TIP_CHIME: process.env.DASHBOARD_DEFAULT_CHIME || 'https://static.livesafemobile.com/1/assets-dash/hey_listen.mp3'
};
