import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  EnterpriseId,
  Location,
  Organization,
  OrganizationId,
  OrganizationTree
} from '@dashboard/core/models';
import { Observable } from 'rxjs';
import { SessionService } from './session.service';
import { environment } from '@dashboard/env';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  private url = `${environment.DASHBOARD_APP_API}/organizations`;
  constructor(private http: HttpClient, private $session: SessionService) { }

  createOrganization(
    name: string,
    location: Location,
    parentId?: OrganizationId
  ): Observable<Organization> {
    const enterpriseId: EnterpriseId = this.$session.getCurrentEnterprise().id;

    return this.http.post<Organization>(this.url, {
      enterpriseId,
      parentId,
      name,
      location
    });
  }

  updateOrganization(
    organizationId: OrganizationId,
    name: string,
    location?: Location,
    description?: string,
    parentId?: OrganizationId
  ): Observable<Organization> {
    return this.http.post<Organization>(`${this.url}/${organizationId}`, {
      organizationId,
      name,
      location,
      description,
      parentId
    });
  }

  getOrganization(organizationId: OrganizationId): Observable<Organization> {
    return this.http.get<Organization>(`${this.url}/${organizationId}`);
  }

  getOrganizationChildren(organizationId: OrganizationId): Observable<OrganizationTree[]> {
    return this.http.get<OrganizationTree[]>(`${this.url}/${organizationId}/children`);
  }
}
