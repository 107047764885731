import {
  Directive,
  OnInit,
  OnDestroy,
  Input,
  ComponentFactoryResolver,
  ViewContainerRef,
  ChangeDetectorRef
} from '@angular/core';
import { RouterOutlet, ChildrenOutletContexts } from '@angular/router';

@Directive({
  // tslint:disable-next-line
  selector: 'dash-named-outlet',
  exportAs: 'outlet'
})
export class NamedOutletDirective implements OnInit, OnDestroy {
  outlet: RouterOutlet;

  @Input() name: string | number;

  constructor(
    private childOutletContexts: ChildrenOutletContexts,
    private viewContainerRef: ViewContainerRef,
    private resolver: ComponentFactoryResolver,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.outlet = new RouterOutlet(
      this.childOutletContexts,
      this.viewContainerRef,
      this.resolver,
      this.name.toString(),
      this.cd
    );

    this.outlet.ngOnInit();
  }

  ngOnDestroy() {
    if (this.outlet) {
      this.outlet.ngOnDestroy();
    }
  }
}
