import {
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  QueryList,
  ViewChild
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatFormField } from '@angular/material/form-field';
import { FocusService } from '@dashboard/core';

@Component({
  selector: 'dash-editable-info-card',
  templateUrl: './editable-info-card.component.html',
  styleUrls: ['./editable-info-card.component.scss']
})
export class EditableInfoCardComponent implements AfterViewInit {
  @HostBinding('class.readonly')
  readonly: boolean = true;

  @Output() edit: EventEmitter<void> = new EventEmitter<void>();
  @Output() submit: EventEmitter<void> = new EventEmitter<void>();
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();

  @Input() form: FormGroup;
  @Input() formName: string = '';

  @ViewChild('editButton') editButton: ElementRef;
  @ViewChild('content') content: ElementRef;

  @ContentChildren(MatFormField, { descendants: true }) fields: QueryList<MatFormField>;

  constructor(private $focus: FocusService) { }

  ngAfterViewInit(): void {
    this.toggleReadonly(true);
  }

  toggleReadonly(readonly?: boolean): void {
    this.readonly = !!readonly ? readonly : !this.readonly;

    this.fields.forEach((field: MatFormField) => {
      field.floatLabel = this.readonly ? 'always' : 'auto';
    });
  }

  onEdit(): void {
    this.edit.emit();
    this.toggleReadonly(false);
    this.$focus.focus(<HTMLElement>this.content.nativeElement);
  }

  onSubmit(): void {
    this.submit.emit();
    this.toggleReadonly(true);
    this.$focus.focus(<HTMLElement>this.editButton.nativeElement);
  }

  onCancel(): void {
    this.cancel.emit();
    this.toggleReadonly(true);
    this.$focus.focus(<HTMLElement>this.editButton.nativeElement);
  }
}
