<div class="body">
  <!-- Content from the CdkStep is projected here -->
  <ng-container [ngTemplateOutlet]="selected?.stepLabel?.template"></ng-container>
  <ng-container [ngTemplateOutlet]="selected?.content"></ng-container>
</div>

<div class="nav">
  <a
    class="bread-crumb"
    *ngFor="let step of steps; let i = index"
    [ngClass]="{ active: selectedIndex === i }"
    (click)="onSelectStep(i)"
  ></a>
</div>
