import { Component, HostBinding, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'dash-search-field',
  template: `
    <label>
      <span class="label" [ngClass]="{ 'cdk-visually-hidden': srOnlyLabel }">{{ label }}</span>

      <i class="icon-search"></i>

      <input
        #text
        type="text"
        (input)="onChange(text.value)"
        [placeholder]="placeholder"
        [value]="value"
        [name]="name"
        [maxLength]="maxLength"
        [disabled]="disabled"
        autocomplete="off"
      />
    </label>
  `,
  styleUrls: ['./search-field.component.scss']
})
export class SearchFieldComponent {
  @Input() label: string;
  @Input() srOnlyLabel: boolean = false;
  @Input() name: string;
  @Input() placeholder: string = 'Search';
  @Input() value: string = '';
  @Input() maxLength: number = 1000000;

  @Input()
  @HostBinding('attr.disabled')
  disabled: boolean;

  @Output() searchValueChange = new EventEmitter();

  onChange(res: string): void {
    this.value = res || '';

    this.searchValueChange.emit(this.value);
  }
}
