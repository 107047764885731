import { AccountId, EnterpriseId, RoleId } from './type-tokens';

export class Permissions {
  id: AccountId;
  roles: Role[];
}

export interface Permission {
  id: string;
  name: string;
  description: string;
  enabled: boolean;
}

export interface PermissionGroup {
  id: string;
  name: string;
  description: string;
  permissions: Permission[];
}

export class Role {
  id: RoleId;
  key: string;
  name: string;
  description?: string;
  alert?: string;
  style?: {};
  isDisabled?: boolean;
  permissions: PermissionGroup[];
}

export class EnterpriseRoles {
  id: EnterpriseId;
  roles: Role[];
}

export enum RoleKey {
  ENTERPRISE_OWNER = 'enterprise-owner'
}
