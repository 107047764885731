<ng-template #enterpriseCreatedAlertTemplate
  >Your account and Enterprise URL
  <span class="highlight">{{ subdomain }}.livesafemobile.net</span>
  have been created
</ng-template>

<ng-template #orgCreatedAlertTemplate
  >You've successfully created your organization,
  <span class="highlight">{{ newOrg.name }}</span>
</ng-template>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <dash-create-organization-stepper livesafeMatStepperRouting>
    <cdk-step [stepControl]="form.controls['name']" [label]="'name'">
      <ng-template cdkStepLabel>
        <div class="title">
          Create Your Organization
        </div>
        <div class="subtitle">
          Begin by naming your first organization.
        </div>
      </ng-template>

      <div class="name-form">
        <mat-form-field livesafeMat appearance="outline" stroke="white">
          <mat-label>Organization Name</mat-label>
          <input matInput placeholder="Acme Corporation" formControlName="name" maxlength="50" required />
        </mat-form-field>
        <button
          class="continue-button mt-32"
          cdkStepperNext
          livesafeMat
          mat-raised-button
          color="primary"
          [disabled]="form.controls['name'].invalid"
        >
          Continue
        </button>
      </div>
    </cdk-step>
    <cdk-step [stepControl]="form.controls['location']" [label]="'location'" #locationStep>
      <ng-template cdkStepLabel>
        <div class="title">
          Set Your Organization's Location
        </div>
        <div class="subtitle">
          Enter an address in the field below
        </div>
      </ng-template>

      <div class="location-form">
        <livesafe-map formControlName="location" [requestCurrentLocation]="true"></livesafe-map>
        <button
          class="create-organization-button mt-32"
          type="submit"
          livesafeMat
          mat-raised-button
          color="primary"
          [disabled]="form.invalid"
        >
          Create Organization
          <mat-icon livesafeMat fontSet="material-icons-round">arrow_forward</mat-icon>
        </button>
      </div>
    </cdk-step>
  </dash-create-organization-stepper>
</form>
