import { UrlMatchResult, UrlSegment } from '@angular/router';
import { environment, RuntimeEnv } from 'src/environments/environment';

// TODO: find better alternative to this
// (perhaps matchers as a service will become a feature https://github.com/angular/angular/issues/17145)
export function rootDomainRouteMatcher(segments: UrlSegment[]): UrlMatchResult | null {
  const subdomain = window.location.host
    .replace('www.', '')
    .replace(environment.DASHBOARD_HOST, '')
    .split('.')[0];

  if (!subdomain.length && environment.RUNTIME_ENV !== RuntimeEnv.LocalDev) {
    return {
      consumed: [],
      posParams: segments.reduce((posParams, segment) => {
        posParams[segment.path] = segment.path;
        return posParams;
      }, {})
    } as UrlMatchResult;
  }

  return null;
}
