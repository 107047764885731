import { InteractivityChecker } from '@angular/cdk/a11y';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { filter, take } from 'rxjs/operators';

/**
 * Utilities to help manage focus for accessibility
 * keyboard navigation and voiceover standards
 */
@Injectable({
  providedIn: 'root'
})
export class FocusService {
  constructor(private interactivityChecker: InteractivityChecker, private router: Router) {}

  /**
   * Focuses on a given element or its first focusable descendant
   *
   * @param element element to be focused on
   */
  focus(element: HTMLElement): void {
    // setTimeout ensures the DOM is finished rendering before the function is fired (see: browser UI thread and JS event loop)
    setTimeout(() => {
      const focusableElement: HTMLElement | undefined = this.getFirstFocusable(element);

      if (focusableElement) {
        focusableElement.focus();
      }
    }, 200);
  }

  /**
   * Focuses on a given element after navigation is complete
   *
   * @param element element to be focused on
   * @param linkIsActive whether or not the link is already active (navigation skipped)
   */
  focusAfterNavigation(element: HTMLElement, linkIsActive: boolean = false): void {
    if (linkIsActive) {
      this.focus(element);
    } else {
      this.router.events
        .pipe(
          filter((event: RouterEvent) => event instanceof NavigationEnd),
          take(1)
        )
        .subscribe(() => {
          this.focus(element);
        });
    }
  }

  private getFirstFocusable(element: HTMLElement): HTMLElement | undefined {
    // Check element
    if (this.interactivityChecker.isFocusable(element)) {
      return element;
    }

    const children: HTMLCollection = element.children;
    for (let i = 0; i < children.length; i++) {
      const child: HTMLElement = <HTMLElement>children.item(i);

      // Check children
      if (this.interactivityChecker.isFocusable(child)) {
        return child;
      }

      // Check grandchildren recursively
      const grandchild: HTMLElement | undefined = this.getFirstFocusable(child);
      if (grandchild) {
        return grandchild;
      }
    }
  }
}
