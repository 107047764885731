import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { NgxsDispatchPluginModule } from '@ngxs-labs/dispatch-decorator';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsModule } from '@ngxs/store';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { AuthInterceptor } from 'src/app/core/http-interceptors';

import { FeedState } from './state/feed.state';
import { RealTimeState } from './state/real-time.state';
import { RolesInterceptor } from './http-interceptors/roles.interceptor';
import { environment } from '@dashboard/env';
import { NgxsSelectSnapshotModule } from '@ngxs-labs/select-snapshot';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    NgxsModule.forRoot([FeedState, RealTimeState], {
      developmentMode: environment.NGXS_DEVELOPMENT_MODE
    }),
    NgxsDispatchPluginModule.forRoot(),
    NgxsFormPluginModule.forRoot(),
    NgxsResetPluginModule.forRoot(),
    NgxsSelectSnapshotModule.forRoot()
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    // TODO: this is temporary interceptor to mock Permissions in roles requests.
    { provide: HTTP_INTERCEPTORS, useClass: RolesInterceptor, multi: true }
  ]
})
export class CoreModule {}
