import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AccountId,
  EnterpriseId,
  EnterpriseRoles,
  Permissions,
  Role,
  RoleId
} from '@dashboard/core/models';
import { environment } from 'src/environments/environment';

export interface UpdateAccountRolesRequest {
  roleIds: RoleId[];
}
export interface UpdateAccountRolesResponse {
  id: AccountId;
  roles: Role[];
}

@Injectable({
  providedIn: 'root'
})
export class AuthorizationsIoService {
  constructor(private http: HttpClient) {}

  fetchEnterpriseRoles(id: EnterpriseId): Observable<EnterpriseRoles> {
    const url = `${environment.DASHBOARD_APP_API}/authorizations/enterprises/${id}`;

    return this.http.get<EnterpriseRoles>(url);
  }

  fetchAccountAuthorizations(accountId: AccountId): Observable<Permissions> {
    const url = `${environment.DASHBOARD_APP_API}/authorizations/accounts/${accountId}`;

    return this.http.get<Permissions>(url);
  }

  putAccountRoles(accountId: AccountId, roleIds: RoleId[]): Observable<UpdateAccountRolesResponse> {
    const url = `${environment.DASHBOARD_APP_API}/authorizations/accounts/${accountId}/roles`;
    const request: UpdateAccountRolesRequest = <UpdateAccountRolesRequest>{
      roleIds
    };

    return this.http.put<UpdateAccountRolesResponse>(url, request);
  }

  deleteAccountRole(accountId: AccountId, roleId: RoleId): Observable<RoleId> {
    const url = `${environment.DASHBOARD_APP_API}/authorizations/accounts/${accountId}/roles/${roleId}`;

    return this.http.delete<RoleId>(url);
  }
}
