import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import {
  Organization,
  OrganizationId,
  OrganizationService,
  SessionService,
  Subdomain,
  WindowService
} from '@dashboard/core';

import { OverlayService } from '@livesafe/material';
import { SnackbarService } from '@livesafe/material';
import { LoaderComponent } from '../shared/components/loader/loader.component';

@Component({
  selector: 'dash-create-organization',
  templateUrl: './create-organization.component.html',
  styleUrls: ['./create-organization.component.scss']
})
export class CreateOrganizationComponent implements OnInit, AfterViewInit {
  @Input() parentOrgId: OrganizationId;
  @Input() firstOrg: boolean = true;

  @Output() orgCreated: EventEmitter<Organization> = new EventEmitter<Organization>();

  subdomain: Subdomain;
  newOrg: Organization;

  form: FormGroup;

  @ViewChild('enterpriseCreatedAlertTemplate', { read: TemplateRef })
  enterpriseCreatedAlertTemplate: TemplateRef<any>;

  @ViewChild('orgCreatedAlertTemplate', { read: TemplateRef })
  orgCreatedAlertTemplate: TemplateRef<any>;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private $session: SessionService,
    private $snackbar: SnackbarService,
    private $window: WindowService,
    private $overlay: OverlayService,
    private $organization: OrganizationService
  ) {
    this.subdomain = this.$window.subdomain;

    this.form = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(50)]],
      location: [null, [Validators.required]]
    });
  }

  ngOnInit(): void {
    if (this.firstOrg) {
      const name: AbstractControl = this.form.controls['name'];
      name.setValue(this.$session.getCurrentEnterprise().name);
    }
  }

  ngAfterViewInit(): void {
    if (this.firstOrg) {
      this.$snackbar.info(this.enterpriseCreatedAlertTemplate, true);
    }
  }

  onSubmit(): void {
    this.$overlay.open(LoaderComponent);
    this.$organization
      .createOrganization(this.form.value.name, this.form.value.location, this.parentOrgId)
      .pipe(finalize(() => this.$overlay.close(LoaderComponent)))
      .subscribe(
        (organization: Organization) => {
          if (this.firstOrg) {
            this.router.navigate(['organizations']).then(() => {
              this.$snackbar.info(
                'Your Organization has been created. Please remember your Enterprise URL and bookmark this page.',
                true
              );
            });
          } else {
            this.newOrg = organization;
            this.$snackbar.info(this.orgCreatedAlertTemplate, false, false);
          }

          this.orgCreated.emit(organization);
        },
        () => {
          this.$snackbar.error('An error has occurred. Please try again.');
        }
      );
  }
}
