import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mapTo, tap } from 'rxjs/operators';
import { Account, Email, Enterprise, Phone, RoleId } from '@dashboard/core/models';
import { environment } from 'src/environments/environment';

import { AccountId, EnterpriseId, OrganizationId } from '../models/type-tokens';
import { LoginResponse } from './auth-io.service';
import { SessionService } from './session.service';

export interface AccountResponse {
  account: Account;
}

export interface CreateAccountRequest {
  enterpriseId?: EnterpriseId;
  givenName: string;
  familyName: string;
  email: Email;
  organizations: OrganizationId[];
  roles: RoleId[];
}

export interface UpdateAccountRequest {
  accountId: AccountId;
  givenName: string;
  familyName: string;
  email: Email;
  phone?: Phone;
  organizations?: OrganizationId[];
  roles?: RoleId[];
}

export interface RegisterAccountRequest {
  onboardingToken: string;
  updatedFirstName?: string;
  updatedLastName?: string;
  updatedPassword: string;
}

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  constructor(private http: HttpClient, private $session: SessionService) { }

  getAccount(id: AccountId): Observable<Account> {
    const url = `${environment.DASHBOARD_APP_API}/accounts/${id}`;

    return this.http.get<AccountResponse>(url).pipe(map(res => res.account));
  }

  createAccount(request: CreateAccountRequest): Observable<Account> {
    const currentEnterprise: Enterprise = <Enterprise>this.$session.getCurrentEnterprise();
    const url = `${environment.DASHBOARD_APP_API}/accounts`;

    request.enterpriseId = currentEnterprise.id;

    return this.http.post<Account>(url, request);
  }

  updateAccount(request: UpdateAccountRequest): Observable<Account> {
    const url = `${environment.DASHBOARD_APP_API}/accounts/${request.accountId}`;

    return this.http.post<Account>(url, request);
  }

  registerAccount(request: RegisterAccountRequest): Observable<void> {
    const url = `${environment.DASHBOARD_APP_API}/accounts/registerAndLogIn`;

    return this.http.post<LoginResponse>(url, request).pipe(
      tap((res: LoginResponse) => {
        if (res.organizations.length) {
          this.$session.setCurrentAccount(res.account);
        }
      }),
      mapTo(void 0)
    );
  }
}
