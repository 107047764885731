import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaskDirective, NgxMaskModule } from 'ngx-mask';
import { LiveSafeMaterialModule } from '@livesafe/material';
import { LiveSafeCommonModule } from '@livesafe/common';
import { environment } from 'src/environments/environment';
import { SafePipeModule } from 'safe-pipe';

import {
  EditableInfoCardComponent,
  ImageComponent,
  LoaderComponent,
  MediaCarouselComponent,
  NoSoundDialogComponent,
  OrgTreeNodeComponent,
  SearchFieldComponent,
  SideNavComponent,
  TopNavComponent,
  VideoPlayerComponent,
  UploadComponent,
  UploadCarouselComponent,
  NewMessagesDividerComponent
} from './components';
import {
  FocusOnInitDirective,
  NamedOutletDirective,
  PasswordToggleDirective
} from './directives';

@NgModule({
  imports: [
    CommonModule,
    LiveSafeMaterialModule,
    SafePipeModule,
    LiveSafeCommonModule.forRoot({
      gmapsApiKey: environment.GMAPS_API_KEY
    }),
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot()
  ],
  declarations: [
    NamedOutletDirective,
    SideNavComponent,
    SearchFieldComponent,
    MediaCarouselComponent,
    VideoPlayerComponent,
    NoSoundDialogComponent,
    LoaderComponent,
    EditableInfoCardComponent,
    OrgTreeNodeComponent,
    ImageComponent,
    FocusOnInitDirective,
    PasswordToggleDirective,
    TopNavComponent,
    UploadComponent,
    UploadCarouselComponent,
    NewMessagesDividerComponent
  ],
  exports: [
    SafePipeModule,
    LiveSafeMaterialModule,
    LiveSafeCommonModule,
    NamedOutletDirective,
    RouterModule,
    SideNavComponent,
    TopNavComponent,
    SearchFieldComponent,
    LoaderComponent,
    EditableInfoCardComponent,
    OrgTreeNodeComponent,
    ImageComponent,
    FocusOnInitDirective,
    PasswordToggleDirective,
    MaskDirective,
    UploadComponent,
    UploadCarouselComponent,
    VideoPlayerComponent,
    NewMessagesDividerComponent
  ]
})
export class SharedModule {}
