import { patch } from '@dashboard/core/util';

import { Submitter } from './chat.model';
import { Location } from './location.model';
import { Attachment } from './media.model';
import { OrganizationId, TipId } from './type-tokens';

export class Tip {
  id: TipId;
  submitter?: Submitter;
  organizationId: OrganizationId;
  organizationName: string;
  message: string;
  location?: Location;
  isAnonymous: boolean;
  isEmergency: boolean;
  isClosed: boolean;
  isUnread: boolean;
  submittedAt: string;
  updatedAt: string;
  lastActivityDate: string;
  attachments: Attachment[];
  messageCount: number;

  constructor(seed: Partial<Tip> = {}) {
    patch(this, seed);
  }
}
