import { Component, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { SidebarService } from '@dashboard/core';
import { LiveSafeMatIconRegistry } from '@livesafe/material';
import { Observable } from 'rxjs';
import { delay } from 'rxjs/operators';
import { LIVESAFE_SVGS } from '@dashboard/shared';
import { NavbarService } from './core/services/navbar.service';
import { PubNubService } from './core/services/pubnub.service';

@Component({
  selector: 'dash-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  showNavbar$: Observable<boolean> = this.$navbar.show$.asObservable();
  showSidebar$: Observable<boolean> = this.$sidebar.show$.asObservable();

  loadingView: boolean = false;

  constructor(
    private router: Router,
    private liveSafeMatIconRegistry: LiveSafeMatIconRegistry,
    private $navbar: NavbarService,
    private $sidebar: SidebarService,
    private $pubnub: PubNubService
  ) {}


  ngOnInit() {
    this.$pubnub.subscribe();
    this.liveSafeMatIconRegistry.initSvgs(LIVESAFE_SVGS, '../assets/icons');
    this.router.events.pipe(delay(0)).subscribe(e => {
      if (e instanceof NavigationStart) {
        this.loadingView = true;
      } else if (e instanceof NavigationEnd || e instanceof NavigationCancel) {
        this.loadingView = false;
      }
    });
  }
}
