<div
  class="org-node-container"
  tabindex="0"
  (keyup.enter)="toggleChildren()"
  (click)="toggleChildren()"
  role="button"
  [attr.aria-label]="'view children for organization ' + org.id"
  [attr.aria-expanded]="children.length > 0"
>
  <ng-container *ngIf="hasChildren()">
    <i *ngIf="!isExpanded()" class="icon-plus"></i>
    <i *ngIf="isExpanded()" class="icon-minus"></i>
  </ng-container>
  <div class="name">
    {{ org.name }}
  </div>
  <div class="action-button-container" [ngSwitch]="mode">
    <ng-container *ngSwitchCase="'edit'">
      <button
        mat-raised-button
        livesafeMat
        color="accent"
        [routerLink]="['/','organizations',org.id]"
        (click)="$event.stopPropagation()"
        class="edit-org-button"
      >
        Edit
      </button>
      <mat-menu class="action-menu" livesafeMat #menu="matMenu">
        <button mat-menu-item (click)="onAddChild()">
          <mat-icon fontSet="material-icons-round">add_circle</mat-icon> Add Child Org
        </button>
      </mat-menu>
      <button
        mat-icon-button
        livesafeMat
        class="action-menu-trigger"
        type="button"
        [attr.aria-label]="'actions for organization ' + org.id"
        [matMenuTriggerFor]="menu"
        (click)="$event.stopPropagation()"
      >
        <mat-icon fontSet="livesafe-icons" fontIcon="i-kebob"></mat-icon>
      </button>
    </ng-container>
    <ng-container *ngSwitchCase="'add'">
      <button
        mat-raised-button
        livesafeMat
        color="accent"
        class="add-org-button"
        type="button"
        [attr.aria-label]="
          isSelected(org.id) ? 'deselect organization ' + org.id : 'select organization ' + org.id
        "
        (click)="$event.stopPropagation(); onToggleOrgSelected(org.id)"
        [class.selected]="isSelected(org.id)"
      >
        Add
        <div class="icon-container">
          <i class="icon-check amaura"></i>
        </div></button
    ></ng-container>
  </div>
</div>
<div class="children-loading loading" *ngIf="childrenLoading"></div>
<dash-org-tree-node
  *ngFor="let child of children; let i = index"
  [org]="child"
  [mode]="mode"
  (toggleOrgSelected)="onToggleOrgSelected($event)"
  (addChild)="onAddChild()"
  dashFocusOnInit
  [focusOnInitEnabled]="i === 0 || childAdded"
  >{{ child.name }}
</dash-org-tree-node>
