import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  AccountId,
  EnterpriseId,
  EnterpriseRoles,
  Permissions,
  Role,
  RoleId,
  RoleKey
} from '@dashboard/core/models';

import { UpdateAccountRolesResponse, AuthorizationsIoService } from './authorizations-io.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationsService {
  constructor(private $io: AuthorizationsIoService) { }

  getAccountAuthorizations(accountId: AccountId): Observable<Permissions> {
    return this.$io.fetchAccountAuthorizations(accountId);
  }

  getEnterpriseRoles(enterpriseId: EnterpriseId): Observable<EnterpriseRoles> {
    return this.$io.fetchEnterpriseRoles(enterpriseId);
  }

  updateAccountRoles(accountId: AccountId, roleIds: RoleId[]): Observable<RoleId[]> {
    return this.$io
      .putAccountRoles(accountId, roleIds)
      .pipe(map((res: UpdateAccountRolesResponse) => res.roles.map((role: Role) => role.id)));
  }

  removeAccountRole(accountId: AccountId, roleId: RoleId): Observable<RoleId> {
    return this.$io.deleteAccountRole(accountId, roleId);
  }

  isEnterpriseOwner(accountId: AccountId): Observable<boolean> {
    return this.$io.fetchAccountAuthorizations(accountId).pipe(
      map(res => {
        return res.roles.some(el => el.key === RoleKey.ENTERPRISE_OWNER);
      })
    );
  }
}
