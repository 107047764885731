<div class="checkmark"></div>
<div class="refresh-notification">
  Your browser has been refreshed and we have re-authenticated your account
</div>
<div class="d-f jc-fe ai-c">
  <button
    mat-raised-button
    livesafeMat
    color="primary"
    (click)="closeDialog()"
    dashFocusOnInit
  >
    Ok
  </button>
</div>
