<button
  mat-mini-fab
  livesafeMat
  color="accent"
  class="pencil-container"
  (click)="onEdit()"
  (keyup.enter)="onEdit()"
  [hidden]="!readonly"
  [attr.aria-label]="'edit ' + formName + ' form'"
  #editButton
>
  <mat-icon livesafeMat fontSet="livesafe-icons" fontIcon="i-edit-inline" size="small"></mat-icon>
</button>
<div #content>
  <ng-content *ngIf="!readonly" select="[edit-mode]"></ng-content>
  <ng-content *ngIf="readonly" select="[readonly-mode]"></ng-content>
</div>
<div [hidden]="readonly">
  <button
    mat-raised-button
    livesafeMat
    color="primary"
    class="d-ib"
    type="button"
    [disabled]="!form.valid"
    (click)="onSubmit()"
    (keyup.enter)="onSubmit()"
  >
    Submit
  </button>
  <button
    mat-raised-button
    livesafeMat
    color="accent"
    class="d-ib ml-24"
    type="button"
    (click)="onCancel()"
    (keyup.enter)="onCancel()"
  >
    Cancel
  </button>
</div>
