import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PubNubAngular } from 'pubnub-angular2';
import { SessionService } from './session.service';
import { Tips } from '../state/real-time.actions';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';

export const tipsMessages = {
  tipChatSent: 'TipChatSent',
  tipCreated: 'TipCreated',
  tipStatusChanged: 'TipStatusChanged'
}

class RealTimeMessage {
  message: string;
  timetoken: string;
}

export class TipsRealTimeMessage extends RealTimeMessage {
  tipId: string;
}

@Injectable({
  providedIn: 'root'
})
export class PubNubService {

  @Dispatch()
  tipChatSent(tipMessage) {
    return new Tips.ChatSent(tipMessage);
  }

  @Dispatch()
  tipCreated(tipMessage) {
    return new Tips.Created(tipMessage);
  }

  @Dispatch()
  tipStatusChanged(tipMessage) {
    return new Tips.StatusChanged(tipMessage);
  }

  constructor(private $session: SessionService) { }

  private init() {
    const pnInstance = new PubNubAngular();
    pnInstance.init({
      publishKey: environment.PUBNUB_PUBLISH_KEY,
      subscribeKey: environment.PUBNUB_SUBSCRIBE_KEY
    });

    return pnInstance;
  }

  private get tipsChannel(): string {
    return `tips-${this.$session.getCurrentEnterprise().id}`;
  }

  subscribe() {
    const instance = this.init();
    instance.subscribe({
      channels: [this.tipsChannel],
      triggerEvents: true,
      withPresence: false
    });

    this.tipsMessagesListener(instance);
  }

  private tipsMessagesListener(instance): void {
    instance.getMessage(
      this.tipsChannel,
      (msg) => {
        const data = JSON.parse(msg.message.payload);
        this[this.getAction(data.event)]({
          message: data.event,
          tipId: data.tipId || '',
          timetoken: msg.timetoken
        });
      }
    );
  }

  private getAction(event: string): string {
    return Object.keys(tipsMessages).find(key => tipsMessages[key] === event)!;
  }
}
