import { Component, EventEmitter, HostBinding, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, finalize } from 'rxjs/operators';
import { OrganizationId, LayoutService, LivesafeSegmentService } from '@dashboard/core';
import { LIVESAFE_SVGS } from '../../mat-icons';
import { LiveSafeMatIconRegistry } from '@livesafe/material';

@Component({
  selector: 'dash-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {
  orgId: OrganizationId;

  @Output() linkClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostBinding('class.loading-view')
  loadingView: string | null;

  // TODO: Uncomment when re-enabling feed
  // @Select(state => state.feed.hasUnread) feedHasUnread$: Observable<boolean | undefined>;

  // @Dispatch() getFeedHasUnread = () =>
  //   this.$feed
  //     .getHasUnread()
  //     .pipe(map((hasUnread: boolean) => new SetFeedHasUnread(hasUnread)));

  constructor(
    private router: Router,
    private $livesafeMatIconRegistry: LiveSafeMatIconRegistry,
    public $layout: LayoutService,
    private $segment: LivesafeSegmentService
  ) {}

  ngOnInit() {
    this.$livesafeMatIconRegistry.initSvgs(LIVESAFE_SVGS, '../../../../assets/icons');
    this.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .pipe(
        // TODO: temporary solution until realtime implemented
        // tap(() => this.getFeedHasUnread()),
        finalize(() => {
          this.loadingView = null;
        })
      )
      .subscribe();
  }

  onLinkClicked(value: string, linkActive: boolean) {
    this.linkClicked.emit(linkActive);

    if (!linkActive) {
      this.loadingView = value;
    }

    if (value === 'connect') {
      this.$segment.typedTrack(
        'Connect.Activity',
        'Opened Connect Activity',
        { entryPoint: 'web' }
      );
    }
  }
}
