import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
  FormArray,
  FormControl
} from '@angular/forms';

// export const passwordRegex: RegExp = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]).*$/;
export const containsNumbersRegex: RegExp = /(?=.*[0-9])/;
export const containsLowercaseRegex: RegExp = /(?=.*[a-z])/;
export const containsUppercaseRegex: RegExp = /(?=.*[A-Z])/;
export const containsSpecialCharactersRegex: RegExp = /(?=.*[_\W])/;
export const passwordRegex: RegExp = new RegExp(
  /^/.source +
    containsNumbersRegex.source +
    containsLowercaseRegex.source +
    containsUppercaseRegex.source +
    containsSpecialCharactersRegex.source +
    /.*$/.source
);
export const emailRegex: RegExp = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

export const patternValidator = (regex: RegExp, error: ValidationErrors): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    const valid = regex.test(control.value);

    return valid ? null : error;
  };
};

export const valuesMatchValidator = (group: FormGroup): ValidationErrors | null => {
  const values: string[] = Object.keys(group.controls).map(
    (key: string) => <string>group.controls[key].value
  );
  const nonUniqueValues: Set<string> = new Set(values);

  if (nonUniqueValues.size !== 1) {
    return <ValidationErrors>{ noMatch: true };
  }

  return null;
};

export const passwordValidator = Validators.compose([
  patternValidator(containsNumbersRegex, { hasNumber: true }),
  patternValidator(containsSpecialCharactersRegex, {
    hasSpecialCharacter: true
  }),
  patternValidator(containsUppercaseRegex, { hasUppercase: true }),
  patternValidator(containsLowercaseRegex, { hasLowercase: true }),
  Validators.minLength(8)
]);

export const formArrayDuplicatesValidator = (array: FormArray): ValidationErrors | null => {
  const controls: AbstractControl[] = array.controls;

  for (let i = 0; i < controls.length; i++) {
    const control: AbstractControl = controls[i];

    for (let j = 0; j < controls.length; j++) {
      const compare: AbstractControl = controls[j];

      if (i !== j && control.value === compare.value) {
        return <ValidationErrors>{ hasDuplicates: true };
      }
    }
  }

  return null;
};

export const noWhitespaceValidator = (control: FormControl): ValidationErrors | null => {
  const isWhitespace = (control.value || '').trim().length === 0;
  const isValid = !isWhitespace;

  return isValid ? null : { whitespace: true };
};

export const minLengthArray = (min: number) => {
  return (c: AbstractControl): ValidationErrors | null => {
    return c.value.length >= min ? null : { minLengthArray: false };
  };
};

export const URL_REGEXP = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
export const HEX_COLOR_REGEXP = /#?([A-Fa-f0-9]{6})/;
