import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { MediaId, MediaService, ThumbnailSize } from '@dashboard/core';
import { Observable } from 'rxjs';
import { finalize, take } from 'rxjs/operators';

export enum ImageType {
  Image = 'image',
  Thumbnail = 'thumbnail'
}

@Component({
  selector: 'dash-image',
  template: `
    <img [src]="imageSrc" *ngIf="imageSrc && !loading" />
    <div
      class="error-logo"
      *ngIf="error && !loading"
      tabindex="0"
      role="button"
      aria-label="reload image"
    >
      !
    </div>
    <img src="assets/loader.svg" *ngIf="loading" />
  `,
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnChanges {
  @Input() mediaId: MediaId;
  @Input() type: ImageType = ImageType.Image;
  @Input() thumbnailSize: ThumbnailSize = ThumbnailSize.XSMALL;
  @Input() url: string;
  @HostBinding('class.loading')
  loading: boolean = true;

  @HostBinding('class.error')
  error: boolean = false;

  @Output() selected: EventEmitter<void> = new EventEmitter<void>();

  imageSrc: SafeUrl;

  constructor(private $media: MediaService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.mediaId || changes.url) {
      this.getImageSrc();
    }
  }

  getImageSrc(): void {
    if (this.mediaId) {
      this.getImageSrcFromAPI();
    } else if (this.url) {
      this.getImageSrcFromURL();
    }
  }

  @HostListener('keyup.enter')
  @HostListener('click')
  retry(): void {
    if (this.error && this.type === ImageType.Image) {
      this.getImageSrc();
    } else {
      this.selected.emit();
    }
  }

  private getImageSrcFromAPI() {
    let request: Observable<SafeUrl>;

    switch (this.type) {
      case ImageType.Thumbnail:
        request = this.$media.getThumbnailImage(this.mediaId, this.thumbnailSize);
        break;
      case ImageType.Image:
      default:
        request = this.$media.getMedia(this.mediaId);
    }

    this.loading = true;
    request
      .pipe(
        take(1),
        finalize(() => (this.loading = false))
      )
      .subscribe(
        (imageSrc: string) => {
          this.imageSrc = imageSrc;
          this.error = false;
        },
        () => (this.error = true)
      );
  }

  private getImageSrcFromURL() {
    this.imageSrc = this.url;
    this.loading = false;
  }
}
