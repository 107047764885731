import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'dash-no-sound-dialog',
  templateUrl: './no-sound-dialog.component.html',
  styleUrls: ['./no-sound-dialog.component.scss']
})
export class NoSoundDialogComponent {
  constructor(public dialog: MatDialogRef<NoSoundDialogComponent>) {}

  closeDialog() {
    this.dialog.close();
  }
}
