import { CdkStepper } from '@angular/cdk/stepper';
import { Component } from '@angular/core';

@Component({
  selector: 'dash-create-organization-stepper',
  templateUrl: './create-organization-stepper.component.html',
  styleUrls: ['./create-organization-stepper.component.scss'],
  providers: [{ provide: CdkStepper, useExisting: CreateOrganizationStepperComponent }]
})
export class CreateOrganizationStepperComponent extends CdkStepper {}
