import { Injectable } from '@angular/core';
import { Enterprise, Organization, Account } from '@dashboard/core/models';

import { StorageService } from './storage.service';
import { LivesafeSegmentService } from '@dashboard/segment';

export const currentEnterprise = 'CURRENT_ENTERPRISE_KEY';
export const currentAccount = 'CURRENT_ACCOUNT_KEY';
export const organizations = 'ORGANIZATIONS_KEY';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  constructor(
    private $storage: StorageService,
    private $segment: LivesafeSegmentService
  ) { }

  setCurrentEnterprise(enterprise: Enterprise): void {
    this.$storage.setItem(currentEnterprise, JSON.stringify(enterprise));
    this.$segment.enterpriseId = enterprise.id;
  }

  getCurrentEnterprise(): Enterprise {
    const val = this.$storage.getItem(currentEnterprise);

    if (val) {
      try {
        return JSON.parse(val) as Enterprise;
      } catch (err) {
        throw new Error(`SessionService: could not parse value for current enterprise.\n${err}`);
      }
    }

    throw new Error(`SessionService: current enterprise not set.`);
  }

  setOrganizations(orgs: Organization[]): void {
    this.$storage.setItem(organizations, JSON.stringify(orgs));
  }

  getOrganizations(): Organization[] {
    const val = this.$storage.getItem(organizations);

    if (val) {
      try {
        return JSON.parse(val) as Organization[];
      } catch (err) {
        throw new Error(`SessionService: could not parse value for organizations.\n${err}`);
      }
    }

    throw new Error(`SessionService: organizations not set.`);
  }

  setCurrentAccount(account: Account): void {
    this.$segment.identify(account.id, account.mediumName, account.emails && account.emails[0]?.address);
    this.$storage.setItem(currentAccount, JSON.stringify(account));
  }

  getCurrentAccount(): Account {
    const val = this.$storage.getItem(currentAccount);

    if (val) {
      try {
        return JSON.parse(val) as Account;
      } catch (err) {
        throw new Error(`SessionService: could not parse value for current account.\n${err}`);
      }
    }

    throw new Error(`SessionService: current account not set.`);
  }
}
