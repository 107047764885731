import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SnackbarService } from '@livesafe/material';
import { Observable, of, pipe, throwError, UnaryFunction } from 'rxjs';
import { catchError, mapTo, tap } from 'rxjs/operators';
import { EmailAddress } from '@dashboard/core/models';

import { AuthIoService, LoginResponse, SessionResponse } from './auth-io.service';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private router: Router,
    private $io: AuthIoService,
    private $session: SessionService,
    private $snackbar: SnackbarService
  ) { }

  login(
    emailAddress: EmailAddress,
    password: string,
    rememberMe: boolean = false
  ): Observable<void> {
    return this.$io.postLogin(emailAddress, password, rememberMe).pipe(this.handleLogin());
  }

  loginWithTokenId(tokenId: string): Observable<void> {
    return this.$io.postTokenIdLogin(tokenId).pipe(this.handleLogin());
  }

  loginWithClientOnboardingToken(token: string): Observable<void> {
    return this.$io.postLoginWithClientOnboardingToken(token).pipe(this.handleLogin());
  }

  private handleLogin(): UnaryFunction<Observable<LoginResponse>, Observable<void>> {
    return pipe(
      tap((res: LoginResponse) => {
        this.$session.setCurrentAccount(res.account);
        this.$session.setOrganizations(res.organizations);
      }),
      catchError((err: HttpErrorResponse) => {
        switch (err.status) {
          case 403:
            this.$snackbar.error(
              `Sorry, but we're not able to find your username or password. If you are trying to submit a tip please use the LiveSafe App.`
            );
            break;
          default:
            this.$snackbar.error('An error has occurred. Please try again.');
        }
        return throwError(err);
      }),
      mapTo(void 0)
    );
  }

  isAuthenticated(): Observable<boolean> {
    return this.$io.fetchIsAuthenticated().pipe(
      mapTo(true),
      catchError(() => of(false))
    );
  }

  logout(): Observable<void> {
    return this.$io.postLogout().pipe(
      tap(() => {
        this.router.navigate(['']);
      })
    );
  }

  forgotPassword(emailAddress: EmailAddress): Observable<void> {
    return this.$io.postForgotPassword(emailAddress);
  }

  changePassword(token: string, newPassword: string): Observable<void> {
    return this.$io.postChangePassword(token, newPassword);
  }

  getSession(): Observable<SessionResponse> {
    return this.$io.fetchIsAuthenticated();
  }
}
