import { Component, Input, forwardRef, EventEmitter, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { OverlayService, SnackbarService } from '@livesafe/material';
import { AlertDialogComponent } from '@livesafe/common';
import { FileModel } from 'src/app/core/models/file.model';
import { UploadCarouselComponent } from '../upload-carousel/upload-carousel.component';

type MediaType = 'image' | 'video' | 'audio' | 'unknown';

@Component({
  selector: 'dash-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UploadComponent),
      multi: true
    }
  ]
})
export class UploadComponent implements ControlValueAccessor {
  @Input() name = 'Upload';
  @Input() accept = 'image/*';
  @Input() multiple = true;
  @Input() maxFileSize = Infinity;
  @Input() readonly = false;
  @Input() hide = false;
  @Input()
  get value() {
    return this.fileList.filter(file => !file.error);
  }
  set value(list) {
    this.fileList = list;
  }
  @Output() attachedFiles: EventEmitter<number> = new EventEmitter<number>();

  readonly defaultImage = `assets/images/media.svg`;
  fileList: FileModel[] = [];
  private _onChange = (_: FileModel[]) => {};

  constructor(
    private $overlay: OverlayService,
    private $snackbar: SnackbarService,
    private $dialog: MatDialog
  ) {}

  writeValue(fileList: FileModel[]): void {
    this.fileList = fileList;
  }
  registerOnChange(fn: any): void {
    this._onChange = fn;
  }
  registerOnTouched(_): void {}

  /**
   * Get files and their preview images
   */
  onChange(files: FileList, form: HTMLFormElement): Promise<FileModel[]> {
    if (files.length === 0) {
      return Promise.resolve(this.fileList);
    }

    this.attachedFiles.emit(files.length);

    return this.getFileRefs(Array.from(files)).then(res => {
      form.reset();
      this.writeValue(this.multiple ? [...this.fileList, ...res] : res);
      this._onChange(this.value);
      return this.fileList;
    });
  }

  removeFile(index: number): void {
    this.$dialog
      .open(AlertDialogComponent, {
        width: '300px',
        data: {
          message: 'Are you sure you want to delete this media?',
          buttons: [
            { label: 'Cancel', color: 'accent' },
            { label: 'Delete', value: true }
          ]
        }
      })
      .afterClosed()
      .subscribe(answer => {
        if (answer) {
          this.fileList = this.fileList.filter((_, i) => i !== index);
          this._onChange(this.fileList);
        }
      });
  }

  openMediaModal(index: number = 0): void {
    this.$overlay.open(UploadCarouselComponent, {
      selectedIndex: index,
      media: this.fileList
    });
  }

  private getFileRefs(files: File[]): Promise<FileModel[]> {
    return Promise.all(files.map(file => this.getThumbnail(file))).then(res =>
      res.map(({ preview, format }, i) => {
        const file = files[i];

        return {
          data: file,
          preview,
          format,
          size: this.getFileSize(file.size),
          error: this.fileHasError(file)
        };
      })
    );
  }

  private getThumbnail(file: File): Promise<{ preview: string; format: string }> {
    return new Promise(resolve => {
      if (file.size < this.maxFileSize) {
        const format = this.getFileFormat(file);
        // We must prevent loading large files into the browser
        if (format !== 'unknown') {
          const reader = new FileReader();
          reader.onload = () => resolve({ preview: (reader.result || '').toString(), format });
          reader.readAsDataURL(file);
        } else {
          resolve({ preview: this.defaultImage, format });
        }
      }
    });
  }

  private getFileFormat(file: File): MediaType {
    if (file.type.indexOf('audio') > -1) {
      return 'audio';
    } else if (file.type.indexOf('video') > -1) {
      return 'video';
    } else if (file.type.indexOf('image') > -1) {
      return 'image';
    }
    return 'unknown';
  }

  private getFileSize(size: number): string {
    if (size < 1000) {
      return size + 'b';
    } else if (size >= 1000 && size < 1000000) {
      return Math.round(size / 1000) + ' kb';
    } else {
      return Math.round(size / 1000000) + ' mb';
    }
  }

  private fileHasError(file: File): boolean {
    if (file.size > this.maxFileSize) {
      this.$snackbar.error(
        `There was an error uploading ${file.name}. File size too big - ${this.getFileSize(
          this.maxFileSize
        )} max`
      );
    }
    return file.size > this.maxFileSize;
  }
}
