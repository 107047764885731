<nav>
  <a [routerLink]="['/organizations']" class="livesafe-icon">
    <span class="cdk-visually-hidden">Homepage</span>
    <i class="icon-ls icon-ls-dims"></i>
  </a>

  <!-- <a [routerLink]="['/organization', orgId, 'home']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: false }"
    [class.loading-view-link]="loadingView === 'home'" #rlaHome="routerLinkActive"
    (click)="onLinkClicked('home', rlaHome.isActive)" mat-icon-button livesafeMat test-name="homepage">
    <span class="cdk-visually-hidden">Homepage</span>
    <mat-icon fontSet="material-icons-round">home</mat-icon>
  </a> -->

  <!-- <a [routerLink]="['/communications']" [routerLinkActive]="'active'"
    [class.loading-view-link]="loadingView === 'communications'" #rlaCommunications="routerLinkActive"
    (click)="onLinkClicked('communications', rlaCommunications.isActive)" matBadgeSize="extra-small"
    matBadgeColor="warn" matBadgePosition="after" matBadge="&#8288;" [matBadgeHidden]="!(feedHasUnread$ | async)"
    mat-icon-button livesafeMat test-name="feed">
    <span class="cdk-visually-hidden">Feed</span>
    <span class="cdk-visually-hidden" *ngIf="feedHasUnread$ | async">(Which Has Unread Items)</span>
    <mat-icon fontSet="material-icons-round">vertical_split</mat-icon>
  </a> -->

  <!-- <a
    [routerLink]="['/organization', orgId]" mat-icon-button livesafeMat test-name="analytics" *ngIf="!($layout.isSmallScreen$ | async)">
    <span class="cdk-visually-hidden">Analytics</span>
    <mat-icon fontSet="material-icons-round">bar_chart</mat-icon>
  </a> -->

  <a
    [routerLink]="['/users']"
    [routerLinkActive]="'active'"
    [class.loading-view-link]="loadingView === 'users'"
    #rlaUsers="routerLinkActive"
    (click)="onLinkClicked('users', rlaUsers.isActive)"
    *ngIf="!($layout.isSmallScreen$ | async)"
    mat-icon-button
    livesafeMat
    test-name="user_management"
  >
    <span class="cdk-visually-hidden">User Management</span>
    <mat-icon fontSet="material-icons-round">people</mat-icon>
  </a>

  <a [routerLink]="['/broadcasts']" [routerLinkActive]="'active'"
    [class.loading-view-link]="loadingView === 'broadcasts'" #rlaBroadcasts="routerLinkActive"
    (click)="onLinkClicked('broadcasts', rlaBroadcasts.isActive)" mat-icon-button livesafeMat test-name="broadcasts">
    <span class="cdk-visually-hidden">Broadcasts</span>
    <mat-icon fontSet="material-icons-round">wifi_tethering</mat-icon>
  </a>

  <a
    [routerLink]="['/organizations']"
    [routerLinkActive]="'active'"
    [class.loading-view-link]="loadingView === 'configurations'"
    #rlaConfigurations="routerLinkActive"
    (click)="onLinkClicked('configurations', rlaConfigurations.isActive)"
    *ngIf="!($layout.isSmallScreen$ | async)"
    mat-icon-button
    livesafeMat
    test-name="enterprise_configurations"
  >
    <span class="cdk-visually-hidden">Enterprise Configurations</span>
    <mat-icon fontSet="material-icons-round">settings</mat-icon>
  </a>

  <a [routerLink]="['/connect']" [routerLinkActive]="'active'"
    [class.loading-view-link]="loadingView === 'connect'" #rlaConnect="routerLinkActive"
    (click)="onLinkClicked('connect', rlaConnect.isActive)" mat-icon-button livesafeMat test-name="connect">
    <span class="cdk-visually-hidden">Connect</span>
    <mat-icon fontSet="livesafe-icons" svgIcon='connect'></mat-icon>
  </a>

  <a
    [routerLink]="['/menu']"
    [routerLinkActive]="'active'"
    [class.loading-view-link]="loadingView === 'menu'"
    #rlaMenu="routerLinkActive"
    (click)="onLinkClicked('menu', rlaMenu.isActive)"
    mat-icon-button
    livesafeMat
    *ngIf="$layout.isSmallScreen$ | async"
  >
    <span class="cdk-visually-hidden">Menu</span>
    <mat-icon fontSet="material-icons-round">menu</mat-icon>
  </a>
</nav>
