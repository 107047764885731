import { TipsRealTimeMessage } from '../services/pubnub.service';

export namespace Tips {
  export class StatusChanged {
    static readonly type = '[Real Time] Tip status changed';
    constructor(public payload: TipsRealTimeMessage) {}
  }
  export class ChatSent {
    static readonly type = '[Real Time] Tip chat sent';
    constructor(public payload: TipsRealTimeMessage) {}
  }
  export class Created {
    static readonly type = '[Real Time] Tip created';
    constructor(public payload: TipsRealTimeMessage) {}
  }
}
