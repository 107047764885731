import { Component} from '@angular/core';

@Component({
  selector: 'dash-new-messages-divider',
  templateUrl: './new-messages-divider.component.html',
  styleUrls: ['./new-messages-divider.component.scss']
})
export class NewMessagesDividerComponent {
  constructor() {}

}
