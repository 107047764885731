<div class="view-loader" *ngIf="loadingView"></div>

<nav class="side-nav" *ngIf="showNavbar$ | async">
  <router-outlet name="side-nav"></router-outlet>
</nav>

<div class="sidebar" *ngIf="showSidebar$ | async">
  <router-outlet name="sidebar"></router-outlet>
</div>

<nav class="top-nav" *ngIf="showNavbar$ | async">
  <router-outlet name="top-nav"></router-outlet>
</nav>

<main>
  <router-outlet></router-outlet>
</main>
