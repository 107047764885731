import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Account, Enterprise, OrganizationTree, DashSort } from '@dashboard/core/models';
import { Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';
import { Subdomain } from '../models/type-tokens';
import { environment } from 'src/environments/environment';
import { SessionService } from './session.service';

export const ACCOUNTS_PAGE_SIZE = 50;

export interface EnterpriseResponse {
  enterprise: Enterprise;
}

export interface EnterpriseAccountsRequest {
  sort?: string[];
  skip?: number;
  limit?: number;
}

@Injectable({
  providedIn: 'root'
})
export class EnterpriseService {
  private url = `${environment.DASHBOARD_APP_API}/enterprises`;

  constructor(private http: HttpClient, private $session: SessionService) { }

  getEnterprise(subdomain: Subdomain): Observable<Enterprise> {
    return this.http.get<EnterpriseResponse>(`${this.url}/subdomains/${subdomain}`).pipe(pluck('enterprise'));
  }

  getEnterpriseOrganizations(): Observable<OrganizationTree[]> {
    const currentEnterprise = this.$session.getCurrentEnterprise() as Enterprise;

    return this.http.get<OrganizationTree[]>(`${this.url}/${currentEnterprise.id}/organizations`);
  }

  getEnterpriseAccounts(skip: number = 0, sorts?: DashSort[]): Observable<Account[]> {
    const currentEnterprise = this.$session.getCurrentEnterprise() as Enterprise;
    const params: EnterpriseAccountsRequest = {
      skip,
      limit: ACCOUNTS_PAGE_SIZE
    };

    if (sorts) {
      params.sort = sorts.map((sort: DashSort) => sort.toString());
    }

    // TODO: Remove filter (temporary solution to hide SDK accounts)
    return this.http.get<Account[]>(`${this.url}/${currentEnterprise.id}/accounts`, { params: <HttpParams>params })
      .pipe(
        map((accounts: Account[]) => accounts.filter((account: Account) => account.mediumName))
      );
  }
}
