import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { Account } from '@dashboard/core/models';
import { AuthService } from './auth.service';
import { AccountService } from './account.service';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class AccountResolverService implements Resolve<Account> {

  constructor(
    private $auth: AuthService,
    private $account: AccountService,
    private $session: SessionService
  ) { }

  resolve(): Observable<Account> {
    return this.$auth.getSession().pipe(
      switchMap((res) => this.$account.getAccount(res.accountId)),
      tap(res => this.$session.setCurrentAccount(res))
    );
  }
}
