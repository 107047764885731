
    <div class="menu-bar">
      <div class="button-container">
        <button
          (click)="close()"
          aria-label="close media carousel"
          class="close-button"
          dashFocusOnInit
        >
          <i class="icon-x"></i>
        </button>
      </div>
    </div>
    <div class="stage">
      <div class="info">
        <div class="title"></div>
        <div class="thumbnail-container" *ngIf="hasNext() || hasPrevious()">
          <div class="thumbnail" *ngFor="let attachment of media; let i = index">
            <dash-image
              *ngIf="attachment.format === 'image'"
              [ngClass]="{ selected: i === selectedIndex }"
              (selected)="select(i)"
              role="button"
              tabindex="0"
              [url]="attachment.preview"
              [attr.aria-label]="'view image ' + i + ' in media carousel'"
            ></dash-image>

            <div class="play-button" *ngIf="attachment.format === 'video'">
              <i class="icon-play"></i>
            </div>
            <!-- TODO: using <video> tag as thumbnail is temporary solution while video processing is implemented in Thumbnail core service (NEX-1052) -->
            <video
              *ngIf="attachment.format === 'video'"
              [ngClass]="{ selected: i === selectedIndex }"
              preload
              (click)="select(i)"
              role="button"
              aria-label="Navigate to selected video in media carousel"
            >
              <source [src]="attachment.preview" />
              <source [src]="attachment.preview" [type]="attachment.data.type" />
            </video>
          </div>
        </div>
      </div>
      <div class="carousel">
        <button
          class="previous-button"
          [disabled]="!hasPrevious()"
          (click)="previous()"
          *ngIf="media.length > 1"
        >
          <img src="assets/icons/arrow-left.svg" />
        </button>
        <dash-image *ngIf="selected.format === 'image'" [url]="selected.preview"></dash-image>
        <dash-video-player
          *ngIf="selected.format === 'video'"
          [src]="selected.preview"
        ></dash-video-player>

        <button
          class="next-button"
          [disabled]="!hasNext()"
          (click)="next()"
          *ngIf="media.length > 1"
        >
          <img src="assets/icons/arrow-right.svg" />
        </button>
      </div>
    </div>
