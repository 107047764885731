import { Injectable } from '@angular/core';
import { SegmentService } from 'ngx-segment-analytics';
import { Subscription, Subject } from 'rxjs';
import { tap, take, takeUntil } from 'rxjs/operators';
import { EnterpriseId, BroadcastId } from '../models';
import { ChannelType } from 'src/app/broadcast/broadcast.service';
import { SegmentRegistry } from './';

export enum BroadcastSegmentEventState {
  Added = 'added',
  Removed = 'removed',
  On = 'on',
  Off = 'off',
  Open = 'open',
  Closed = 'closed'
}

export interface BroadcastEvent {
  broadcastId: BroadcastId;
  channelType: ChannelType;
  numberPotentialUsers: number;
  numberOrgsTargeted: number;
  emailMessageLength: number;
  smsMessageLength: number;
  pushMessageLength: number;
  voiceMessageLength: number;
  composeBroadcastStep: string
}

@Injectable({
  providedIn: 'root'
})
export class LivesafeSegmentService {
  private enterpriseIdVal: EnterpriseId;

  subscription: Subscription;
  unsubscribe$: Subject<void> = new Subject();

  constructor(private $segment: SegmentService) { }

  set enterpriseId(enterpriseId: EnterpriseId) {
    this.enterpriseIdVal = enterpriseId;
  }

  page(page: string, options: any = {}): void {
    this.$segment.page(page, {
      enterpriseId: this.enterpriseIdVal,
      ...options
    });
  }

  /** @deprecated Please use typedTrack instead */
  track(event: string, options: any = {}): void | Promise<SegmentService> {
    this.$segment.track(event, {
      enterpriseId: this.enterpriseIdVal,
      ...options
    });
  }

  /**
   * This method will be renamed to `track` once all deprecated usages of it are removed
   * @param namespace
   * @param event
   * @param data
   */
  typedTrack<
    T extends keyof SegmentRegistry,
    K extends keyof SegmentRegistry[T],
    S extends SegmentRegistry[T][K]>(namespace: T, event: K, data: S): Promise<SegmentService> {
      return this.$segment.track(event.toString(), {
        namespace,
        enterpriseId: this.enterpriseIdVal,
        ...data
      });
  }

  identify(userId: string, name: string = '', email: string, options: any = {}): void {
    this.$segment.identify(userId, {name, email}, options);
  }

  createBroadcastSegmentEvent(
    broadcastId: BroadcastId,
    channelType: ChannelType,
    numberPotentialUsers: number,
    numberOrgsTargeted: number,
    emailMessageLength: number,
    smsMessageLength: number,
    pushMessageLength: number,
    voiceMessageLength: number,
    composeBroadcastStep?: string
  ): BroadcastEvent {
    const event = {
      broadcastId,
      channelType,
      numberPotentialUsers,
      numberOrgsTargeted,
      emailMessageLength,
      smsMessageLength,
      pushMessageLength,
      voiceMessageLength,
      composeBroadcastStep
    }

    return this.filterEvent(event);
  }

  filterEvent(event) {
    Object.keys(event).forEach((key) => (event[key] === null || event[key] === 0 || event[key] === 0) && delete event[key]);
    return event;
  }

  onFocus(form: any, inputReference: string, composeBroadcastStep: string, previewComponent?: string): void {
    let val;

    if (form.controls) {
      val = form.get(inputReference)!.valueChanges;
    } else {
      val = form.valueChanges;
    }

    this.subscription = val
      .pipe(
        takeUntil(this.unsubscribe$),
        take(1),
        tap(() => {
          const event = {
            composeBroadcastStep,
            inputReference,
            previewComponent
          };
          this.filterEvent(event);
          this.typedTrack('Broadcast.Compose', 'Started Typing', event);
        })
      )
      .subscribe();
  }

  onBlur(): void {
    this.subscription.unsubscribe();
  }
}
